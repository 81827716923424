// import _ from 'lodash';
import { Page } from '../../../components/layout/page';
import { CustomRouteProps } from '../../../lib/components/route';

export default function HomeIndex(props: CustomRouteProps) {

    return (
        <Page
            {...props}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col h-100 d-flex p-0">
                        MAIN PAGE
                    </div>
                </div>
            </div>

        </Page>
    );
}
