const config = {
    pollingTime: 2000,
    client: {
        url: 'https://stalwart.com',
    },
    api: {
        url: 'https://api.stalwart.com',
    },
    ws: {
        url: 'wss://ws.stalwart.com',
    },
};

export default config;
