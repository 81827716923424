import AuthAPI from './auth';
import UsersAPI from './users';
import { IClientAuthStateHandler } from './base-api';

class API {
    auth: AuthAPI;
    users: UsersAPI;

    constructor(
        baseUrl: string,
        clientAuthStateHandler: IClientAuthStateHandler,
    ) {
        this.auth = new AuthAPI(baseUrl, clientAuthStateHandler);
        this.users = new UsersAPI(baseUrl, this.auth);
    }
}

export {
    API,
};
