import * as AppPages from './pages';
import { CustomRouteSpec } from './lib/components/route';

const routes: CustomRouteSpec[] = [
    { path: '/site', pageName: 'website', type: 'public', component: AppPages.Website.Index },

    { path: '/', pageName: 'home', type: 'private', component: AppPages.Home.HomeIndex },

    { path: '/sign-in', pageName: 'sing-in', type: 'non-private', component: AppPages.Auth.SignIn },
    { path: '/sign-up', pageName: 'sing-up', type: 'non-private', component: AppPages.Auth.SignUp },
    { path: '/verification-code', pageName: 'verification-code', type: 'non-private', component: AppPages.Auth.VerificationCode },

    { path: '*', pageName: 'not-found', type: 'public', component: AppPages.Errors.Error404 },
];

export default routes;
